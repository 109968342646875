import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import Section from '../Section'
import Title from '../Section/Title'
import Resume from '../../types/Resume'
import RichText from '../../components/RichText'
import { resumeSections } from '../../contants/resume'
import { ScrollAnchor } from '../../components/ScrollAnchor'

const styles = ({
  palette: {
    text: { secondary },
  },
}: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
    },
    text: {
      color: secondary,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

interface StrapiResume {
  about: Resume['about']
}

interface Query {
  strapiResume: StrapiResume
}

const About = ({ classes }: Props) => {
  const {
    strapiResume: { about },
  }: Query = useStaticQuery(graphql`
    {
      strapiResume {
        about
      }
    }
  `)

  /**
   * This anchor works because we scroll to it using a package. Otherwise, it
   * would not work. See the docs for more info.
   */
  return (
    <Section classes={{ root: classes.root }}>
      <ScrollAnchor id={resumeSections.about} />
      <Title>About Me</Title>
      <RichText content={about} classes={{ root: classes.text }} />
    </Section>
  )
}

export default withStyles(styles)(About)
