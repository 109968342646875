import React from 'react'

import getList from '../../components/ProjectsList'
import { ResumeProjectInfo } from './types'
import Project from './Project'
import Section from '../Section'
import Title from '../Section/Title'
import { graphql, useStaticQuery } from 'gatsby'
import { ProjectsQuery } from '../../types/Project'

const List = getList<ResumeProjectInfo>()

interface Query extends ProjectsQuery<ResumeProjectInfo> {}

export default function Projects() {
  const {
    allStrapiProjects: { nodes: projects },
  }: Query = useStaticQuery(graphql`
    query ResumeProjects {
      allStrapiProjects(
        sort: { order: ASC, fields: order }
        filter: { showOnResume: { eq: true } }
      ) {
        nodes {
          slug
          title
          description
          url
          languages {
            name
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Title>
        Featured
        <br />
        Projects
      </Title>
      <List Project={Project} projects={projects} />
    </Section>
  )
}
