import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Container,
  Theme,
} from '@material-ui/core'

import Title from './Title/index'
import Toolbar from './Toolbar'
import Image from './Image/index'
import { paddingY } from '../../components/Layout/Nav'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  const md = up('md')
  return createStyles({
    root: {
      marginTop: '3.8rem',
      marginBottom: '5.625rem',
      [md]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: `calc(2.375rem - ${paddingY})`,
        marginBottom: '8rem',
      },
      [xl]: {
        justifyContent: 'center',
        marginTop: `calc(7.625rem - ${paddingY})`,
        marginBottom: '10.9375rem',
      },
    },
    body: {
      [md]: {
        paddingBottom: '2rem',
      },
      [xl]: {
        paddingBottom: 0,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Hero = ({ classes }: Props) => {
  return (
    <Container component="section" classes={classes}>
      <Image />
      <div className={classes.body}>
        <Title />
        <Toolbar />
      </div>
    </Container>
  )
}

export default withStyles(styles)(Hero)
