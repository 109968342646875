import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { getSizeStyles } from '../../../utils/size'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  const md = up('md')
  return createStyles({
    root: {
      marginRight: '1.125rem',
      padding: '0.625rem 0.5625rem',
      [md]: {
        marginRight: '1.25rem',
        fontSize: '1rem',
        lineHeight: '1.1875rem',
        padding: '0.625rem 0.75rem',
      },
      [xl]: {
        marginRight: '2.625rem',
        fontSize: '1.25rem',
        lineHeight: '1.4375rem',
        padding: '1.1875rem 1.3125rem',
      },
    },
    icon: {
      ...getSizeStyles('0.75rem'),
      [md]: {
        ...getSizeStyles('1rem'),
      },
      [xl]: {
        ...getSizeStyles('1.3125rem'),
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const ResumeButton = ({ classes }: Props) => {
  return (
    <Button
      color="primary"
      classes={classes}
      variant="contained"
      startIcon={<FontAwesomeIcon className={classes.icon} icon={faDownload} />}
      href="https://1drv.ms/b/s!AmPF6g49dzKih9Vp758lJGl-gR-LDw?e=Ko0VLv"
    >
      Resume
    </Button>
  )
}

export default withStyles(styles)(ResumeButton)
