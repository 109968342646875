import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import scrollTo from 'gatsby-plugin-smoothscroll'

import ButtonWithArrow from '../../../components/ButtonWithArrow'
import { resumeSections } from '../../../contants/resume'
import { getSizeStyles } from '../../../utils/size'
import { fontSizeVariants } from '../../../theme/fontSizeVariants'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  const fontMd = '1.25rem'
  return createStyles({
    root: {
      ...fontSizeVariants.body,
      [md]: {
        fontSize: fontMd,
        lineHeight: '1.5rem',
      },
      [xl]: {
        fontSize: '1.5625rem',
        lineHeight: '1.9375rem',
      },
    },
    endIcon: {
      marginLeft: '0.25rem',
      [xl]: {
        marginLeft: '1.4375rem',
      },
      '&, & svg': {
        ...getSizeStyles('0.875rem'),
        [md]: {
          ...getSizeStyles(fontMd),
        },
        [xl]: {
          ...getSizeStyles('1.5rem'),
        },
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const LearnMoreButton = ({ classes }: Props) => {
  return (
    <ButtonWithArrow
      onClick={() => scrollTo(`#${resumeSections.about}`)}
      classes={classes}
    >
      Learn More
    </ButtonWithArrow>
  )
}

export default withStyles(styles)(LearnMoreButton)
