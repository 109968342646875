import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TypographyProps,
} from '@material-ui/core'

import SectionTitle from '../../components/SectionToolbar/Title'
import SectionToolbar from '../../components/SectionToolbar'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '1.875rem',
      [up('md')]: {
        marginBottom: '2.125rem',
      },
      [up('xl')]: {
        marginBottom: '4.5625rem',
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<TypographyProps, 'classes'> {}

const Title = ({ classes, ...other }: Props) => {
  return (
    <SectionToolbar
      title={<SectionTitle variant="h2" {...other} />}
      classes={classes}
    ></SectionToolbar>
  )
}

export default withStyles(styles)(Title)
