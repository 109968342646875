import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import LanguageItem from './Language'
import { Language, ResumeProjectInfo } from '../../../types'

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
      display: 'flex',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  languages: ResumeProjectInfo['languages']
}

const Languages = ({ classes, languages }: Props) => {
  const renderItem = (language: Language, index: number): JSX.Element => {
    return <LanguageItem {...language} key={index} />
  }

  const shownLanguages: Language[] = languages.slice(0, 4)
  const renderedItems: JSX.Element[] = shownLanguages.map(renderItem)

  return <ul className={classes.root}>{renderedItems}</ul>
}

export default withStyles(styles)(Languages)
