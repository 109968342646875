import { withStyles, createStyles, Theme } from '@material-ui/core'

import Dots from '../../../components/Dots'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: 'none',
      [up('xl')]: {
        display: 'block',
        bottom: '-2rem',
        right: '-1.875rem',
        width: '12.375rem',
      },
    },
  })
}

export default withStyles(styles)(Dots)
