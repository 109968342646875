import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import ResumeButton from './Resume'
import LearnMoreButton from './LearnMore'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [up('md')]: {
        justifyContent: 'flex-start',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Toolbar = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <ResumeButton />
      <LearnMoreButton />
    </div>
  )
}

export default withStyles(styles)(Toolbar)
