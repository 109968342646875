import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Language } from '../../../types'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      fontWeight: 500,
      fontSize: '0.6875rem',
      lineHeight: '0.8125rem',
      padding: '0.375rem 0.4375rem',
      borderRadius: '6.25rem',
      listStyle: 'none',
      border: '0.0625rem solid #C0CCDA',
      [md]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        padding: '0.375rem 0.5625rem',
      },
      [xl]: {
        fontSize: '1rem',
        lineHeight: '1.1719rem',
        padding: '0.5625rem 0.8125rem',
      },
      '& + &': {
        marginLeft: '0.3125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, Language {}

const LanguageItem = ({ classes, name }: Props) => {
  return (
    <Typography component="li" classes={classes} color="textSecondary">
      {name}
    </Typography>
  )
}

export default withStyles(styles)(LanguageItem)
