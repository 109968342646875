import React from 'react'

import Technologies from '../components/Technologies'
import DockerIcon from '../components/Technologies/DockerIcon'
import JavaScriptIcon from '../components/Technologies/JavaScript'
import NodeIcon from '../components/Technologies/NodeIcon'
import ReactIcon from '../components/Technologies/React'
import Section from './Section'
import Title from './Section/Title'

export default function TechnologiesUsed() {
  return (
    <Section>
      <Title>
        Technologies
        <br />
        Used
      </Title>
      <Technologies>
        <JavaScriptIcon />
        <ReactIcon />
        <NodeIcon />
        <DockerIcon />
      </Technologies>
    </Section>
  )
}
