import React, { useState } from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import JobsTimeline from './JobsTimeline'
import Section from '../Section'
import Title from '../Section/Title'
import JobInfo from './JobInfo'
import { CollectionWithNodes } from '../../types/Nodes'
import { JobsContext } from './types'
import { graphql, useStaticQuery } from 'gatsby'
import Job from '../../types/Job'

const styles = () => {
  return createStyles({
    body: {
      display: 'flex',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

interface AllStrapiJobs extends CollectionWithNodes<Job> {}

interface Query {
  allStrapiJob: AllStrapiJobs
}

const Worked = ({ classes }: Props) => {
  const [selectedJobIndex, setSelectedJob] =
    useState<JobsContext['selectedJob']>(0)
  const {
    allStrapiJob: { nodes: jobs },
  }: Query = useStaticQuery(graphql`
    {
      allStrapiJob {
        nodes {
          title
          startDate
          endDate
          company {
            name
            shortName
          }
          content
        }
      }
    }
  `)

  const selectedJob: Job = jobs[selectedJobIndex]

  return (
    <Section>
      <Title>
        Places I&apos;ve
        <br />
        Worked
      </Title>
      <div className={classes.body}>
        <JobsTimeline
          selectedJob={selectedJobIndex}
          setSelectedJob={setSelectedJob}
          jobs={jobs}
        />
        <JobInfo {...selectedJob} />
      </div>
    </Section>
  )
}

export default withStyles(styles)(Worked)
