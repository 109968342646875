import React from 'react'
import { JobsContext } from '../types'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import Item from './Item'
import Job from '../../../types/Job'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      padding: 0,
      marginRight: '0.875rem',
      flexShrink: 0,
      [md]: {
        marginRight: '1.75rem',
      },
      [xl]: {
        marginRight: '5.8125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, JobsContext {
  jobs: Job[]
}

function JobsTimeline({ classes, jobs, ...other }: Props) {
  const renderItem = (item: Job, index: number): JSX.Element => {
    return (
      <Item {...item} index={index} key={index} jobs={jobs.length} {...other} />
    )
  }

  const renderedItems: JSX.Element[] = jobs.map(renderItem)

  return <ul className={classes.root}>{renderedItems}</ul>
}

export default withStyles(styles)(JobsTimeline)
