import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Text from './Text'

const styles = ({
  breakpoints: { up, between },
  typography: { h2 },
}: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '1.5625rem',
      [md]: {
        marginBottom: '1.625rem',
      },
      [xl]: {
        marginBottom: '2.1875rem',
      },
    },
    hi: {
      ...h2,
      [between('md', 'lg')]: {
        fontSize: '1.5625rem',
        lineHeight: '1.8125rem',
      },
      [xl]: {
        fontSize: '1.875rem',
        lineHeight: '2.1875rem',
      },
    },
    secondary: {
      fontWeight: 400,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Title = ({ classes }: Props) => {
  return (
    <Text component="h1" classes={{ root: classes.root }}>
      <span className={classes.hi}>Hi,</span>
      <br />
      I&apos;m{' '}
      <Text component="span" color="primary">
        Eduardo
      </Text>
      ,<br />
      <Text
        color="textSecondary"
        component="span"
        className={classes.secondary}
      >
        I am a full-stack
        <br /> engineer
      </Text>
    </Text>
  )
}

export default withStyles(styles)(Title)
