import React, { ReactNode } from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TypographyProps,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      fontSize: '1.875rem',
      lineHeight: '2.1875rem',
      textAlign: 'center',
      [md]: {
        fontSize: '3.125rem',
        lineHeight: '3.6875rem',
        textAlign: 'left',
      },
      [xl]: {
        fontSize: '5rem',
        lineHeight: '5.875rem',
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<TypographyProps, 'classes'> {
  component?: React.ElementType
  children: ReactNode
}

const Text = (props: Props) => {
  return <Typography {...props} />
}

export default withStyles(styles)(Text)
