import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  ButtonProps,
} from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowForward'

const styles = (theme: Theme) => {
  const size: string = '1.3125rem'
  const xl = theme.breakpoints.up('xl')

  return createStyles({
    root: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      textDecoration: 'underline',
      color: theme.palette.text.primary,
      [xl]: {
        fontSize: '1.5625rem',
        lineHeight: '1.8125rem',
      },
    },
    endIcon: {
      width: size,
      height: size,
      marginLeft: '0.5rem',
      color: '#F9944C',
      [xl]: {
        marginLeft: '1.125rem',
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<ButtonProps, 'classes'> {}

const ButtonWithArrow = (props: Props) => {
  return <Button variant="text" endIcon={<ArrowIcon />} {...props} />
}

export default withStyles(styles)(ButtonWithArrow)
