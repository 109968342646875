import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '2.75rem',
      [up('lg')]: {
        marginBottom: '5.6875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Section = ({ classes, children }: Props) => {
  return <section className={classes.root}>{children}</section>
}

export default withStyles(styles)(Section)
