import React from 'react'

import Toolbar from './Toolbar'
import ProjectOfProjectsList from '../../../components/ProjectsList/Project'
import { ResumeProjectInfo } from '../types'

interface Props extends ResumeProjectInfo {}

export default function ResumeProject(props: Props) {
  return <ProjectOfProjectsList {...props} toolbar={<Toolbar {...props} />} />
}
