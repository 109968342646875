import { Box, BoxProps } from '@material-ui/core'
import React, { useMemo } from 'react'
import { NAV_HEIGHT } from './Layout/Nav'

interface Props {
  id: string
}

/**
 * This is used to prevent the page's navbar from covering the component that is
 * being scrolled to.
 */
export function ScrollAnchor({ id }: Props) {
  const styles = useMemo((): BoxProps['sx'] => {
    const size = 0

    return {
      width: size,
      height: size,
      visibility: 'hidden',
      top: `-${NAV_HEIGHT}`,
      position: 'relative',
    }
  }, [])

  return <Box id={id} sx={styles} />
}
