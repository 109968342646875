import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { ResumeProjectInfo } from '../../types'
import Toolbar from '../../../../components/Project/Toolbar'
import Languages from './Languages'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '0.625rem',
      [md]: {
        marginBottom: '0.5625rem',
      },
      [xl]: {
        marginBottom: '0.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, ResumeProjectInfo {}

const ResumeProjectToolbar = ({ languages, ...other }: Props) => {
  return (
    <div>
      <Toolbar {...other} />
      <Languages languages={languages} />
    </div>
  )
}

export default withStyles(styles)(ResumeProjectToolbar)
