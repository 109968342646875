import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core'

import ArchivedProject from './ArchivedProject'
import { showGridBreakpoint } from './styles'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../Section'
import Title from '../Section/Title'
import { ProjectPreview, ProjectsQuery } from '../../types/Project'
import Slider from '../../components/Slider'

const styles = ({ breakpoints: { up, down } }: Theme) => {
  const showGridUp = up(showGridBreakpoint)
  return createStyles({
    slider: {
      [showGridUp]: {
        display: 'none',
      },
    },
    grid: {
      [down('sm')]: {
        display: 'none',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

interface Query extends ProjectsQuery {}

const Archive = ({ classes }: Props) => {
  const {
    allStrapiProjects: { nodes: projects },
  }: Query = useStaticQuery(graphql`
    query ArchiveProjects {
      allStrapiProjects(
        sort: { order: ASC, fields: order }
        filter: { isArchived: { eq: true } }
      ) {
        nodes {
          slug
          title
          description
          url
          languages {
            name
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const renderProject = (
    project: ProjectPreview,
    index: number
  ): JSX.Element => {
    return (
      <ArchivedProject
        {...project}
        key={project.slug}
        index={index}
        itemsNumber={projects.length}
      />
    )
  }

  const renderedProjects: JSX.Element[] = projects.map(renderProject)

  return (
    <Section>
      <Title>
        Archived
        <br />
        Projects
      </Title>
      <Slider className={classes.slider}>{renderedProjects}</Slider>
      <Grid container className={classes.grid}>
        {renderedProjects}
      </Grid>
    </Section>
  )
}

export default withStyles(styles)(Archive)
