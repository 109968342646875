import React from 'react'
import { Container } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Hero from './Hero'
import About from './About'
import TechnologiesUsed from './TechnologiesUsed'
import Worked from './Worked'
import Projects from './Projects'
import Archive from './Archive'
import Social from '../components/Social'
import Contact from '../components/Contact'
import SocialToolbar from '../components/SocialToolbar'

const ResumePage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Eduardo Valencia</title>
      </Helmet>
      <SocialToolbar />
      <Hero />
      <Container>
        <About />
        <TechnologiesUsed />
        <Worked />
        <Projects />
        <Archive />
      </Container>
      <Social />
      <Contact />
    </Layout>
  )
}

export default ResumePage
