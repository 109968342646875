import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

import RichText from '../../components/RichText'
import Job from '../../types/Job'

const styles = ({
  breakpoints: { up },
  palette: {
    text: { secondary },
  },
}: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      maxWidth: '52.3125rem',
    },
    title: {
      fontSize: '1rem',
      lineHeight: '1.6875rem',
      [md]: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
      },
      [xl]: {
        fontSize: '1.875rem',
        lineHeight: '3.375rem',
      },
    },
    date: {
      fontSize: '0.8125rem',
      lineHeight: '1.375rem',
      [md]: {
        fontSize: '0.9375rem',
        lineHeight: '1.6875rem',
      },
      [xl]: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
      },
    },
    body: {
      marginBottom: '1.625rem',
      [md]: {
        marginBottom: '1.1875rem',
      },
      [xl]: {
        marginBottom: '3.375rem',
      },
    },
    content: {
      '& p': {
        color: secondary,
        [md]: {
          fontSize: '1rem',
          lineHeight: '1.6875rem',
        },
        [xl]: {
          fontSize: '1.5625rem',
          lineHeight: '2.625rem',
        },
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, Job {}

const JobInfo = ({
  classes,
  title,
  company: { shortName, name },
  startDate: start,
  endDate: end,
  content,
}: Props) => {
  const getMonth = (date: Date): string =>
    date.toLocaleString('en-US', { month: 'long' })

  const getDate = () => {
    const startDate: Date = new Date(start)
    const startYear: number = startDate.getFullYear()
    if (!end) {
      return `${startYear} - Present`
    }
    const endDate: Date = new Date(end)
    const startMonth: string = getMonth(startDate)
    const endMonth: string = getMonth(endDate)
    const endYear: number = endDate.getFullYear()
    if (startYear !== endYear) {
      return `${startYear} - ${endYear}`
    }
    return `${startMonth} - ${endMonth} ${endYear}`
  }

  const formattedDate: string = getDate()

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Typography component="h2" className={classes.title}>
          {title} @ {shortName || name}
        </Typography>
        <Typography color="textSecondary" className={classes.date}>
          {formattedDate}
        </Typography>
      </div>
      <RichText classes={{ root: classes.content }} content={content} />
    </div>
  )
}

export default withStyles(styles)(JobInfo)
