import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Hidden,
  Theme,
} from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'

import Dots from './Dots'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginRight: '1.8125rem',
      position: 'relative',
      [up('md')]: {
        width: '38%',
      },
      [up('xl')]: {
        marginRight: '5.75rem',
        width: '32.375rem',
      },
    },
    image: {
      zIndex: 1,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Image = ({ classes }: Props) => {
  return (
    <Hidden smDown>
      <div className={classes.root}>
        <StaticImage
          src="./eduardo.jpg"
          alt="Eduardo Valencia"
          className={classes.image}
        />
        <Dots />
      </div>
    </Hidden>
  )
}

export default withStyles(styles)(Image)
