import React, { useCallback } from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { FolderOutlined as Folder, ArrowForward } from '@material-ui/icons'

import { ProjectPreview } from '../../types/Project'
import { showGridBreakpoint } from './styles'
import { getSizeStyles } from '../../utils/size'
import { getWidthAndMarginLeftGenerators } from '../../utils/flex'
import { createProjectLink } from '../../utils/links'
import { navigate } from 'gatsby'

const styles = ({
  breakpoints: { up },
  palette: {
    text: { secondary },
  },
}: Theme) => {
  const showGridUp = up(showGridBreakpoint)
  const lg = up('lg')
  const xl = up('xl')

  // This component will be inside a slider, so don't set any margins on mobile
  return createStyles({
    root: {
      border: '0.0625rem solid #D8D8D8',
      borderRadius: '0.5rem',
      padding: '2.5625rem 1.3125rem 1.875rem',
      [showGridUp]: {
        marginBottom: '1.75rem',
        ...getWidthAndMarginLeftGenerators('1.25rem', 2),
      },
      [lg]: {
        ...getWidthAndMarginLeftGenerators('1.75rem', 3),
      },
      [xl]: {
        padding: '2.5rem',
        ...getWidthAndMarginLeftGenerators('1.875rem', 4),
      },
    },
    icon: {
      ...getSizeStyles('2rem'),
      marginBottom: '1.4375rem',
      color: secondary,
      [xl]: {
        marginBottom: '3.5rem',
      },
    },
    title: {
      marginBottom: '0.75rem',
      color: '#18191F',
      fontSize: '1.3125rem',
      lineHeight: '2.0625rem',
      [xl]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    content: {
      fontSize: '0.875rem',
      lineHeight: '1.6875rem',
      fontWeight: 500,
      marginBottom: '1rem',
      [xl]: {
        marginBottom: '2.5rem',
      },
    },
    button: {
      padding: 0,
      fontSize: '1.0625rem',
    },
  })
}

interface Props extends WithStyles<typeof styles>, ProjectPreview {}

const ArchivedProject = ({ classes, title, description, slug }: Props) => {
  const navigateToProject = useCallback(() => {
    const link: string = createProjectLink(slug)
    navigate(link)
  }, [slug])

  return (
    <Grid item xs={12} className={classes.root}>
      <Folder className={classes.icon} />
      <Typography component="h2" className={classes.title}>
        {title}
      </Typography>
      <Typography color="textSecondary" className={classes.content}>
        {description}
      </Typography>
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        endIcon={<ArrowForward />}
        onClick={navigateToProject}
      >
        Explore
      </Button>
    </Grid>
  )
}

export default withStyles(styles)(ArchivedProject)
