import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from '@material-ui/core'

import { JobsContext } from '../types'
import Job from '../../../types/Job'

interface BaseProps extends JobsContext, Job {
  index: number
  jobs: number
}

const lightColor = '#C0CCDA'

const getColor =
  (color: string) =>
  ({ index, selectedJob }: BaseProps) => {
    return index === selectedJob ? color : lightColor
  }

const styles = ({ breakpoints: { up }, palette: { primary } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      lineHeight: '0.8125rem',
      padding: 0,
      color: getColor(primary.main),
      textAlign: 'left',
      justifyContent: 'flex-start',
      [md]: {
        fontSize: '1rem',
        lineHeight: '1.1875rem',
      },
      [xl]: {
        fontSize: '1.25rem',
        lineHeight: '1.4375rem',
      },
    },
    separator: {
      height: '3.3125rem',
      borderLeft: `0.0625rem dashed ${lightColor}`,
      width: '0.0625rem',
      margin: '0.25rem 0',
      [xl]: {
        height: '4.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const TimelineItem = ({
  classes,
  setSelectedJob,
  company: { name },
  index,
  jobs,
}: Props) => {
  const isLast: boolean = index === jobs - 1

  const handleClick = (): void => {
    setSelectedJob(index)
  }

  return (
    <li className={classes.root}>
      <Button
        variant="text"
        className={classes.title}
        onClick={handleClick}
        fullWidth
      >
        {name}
      </Button>
      {!isLast && <div className={classes.separator} />}
    </li>
  )
}

export default withStyles(styles)(TimelineItem)
